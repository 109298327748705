import React, { useState }  from 'react';
import TableHead from '@mui/material/TableHead';
import { nanoid } from "nanoid";
import {
    Grid,
    Table,
    Paper,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Typography,
    TablePagination
  } from '@mui/material';

export default function ResultTable(props) {
    const [pg ,setpg] = useState(0);
    const [rpg, setrpg] = useState(5);

    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value,10));
        setpg(0)
    }

    return (
        <Grid item xs={12} sm={12} md={6} lg={6} key={nanoid()} sx={{ mt:3 }}>
            <Paper>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="center"  key={nanoid()} colSpan={Object.keys(props.individualTable[0]).length}>
                                <Typography variant="body1">
                                    {props.tableName}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {Object.keys(props.individualTable[0]).map((value) =>
                                <TableCell align="left" key={nanoid()}>
                                    <Typography variant="body1">
                                        {value}
                                    </Typography>
                                </TableCell>
                            )}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {props.individualTable.slice(pg * rpg, pg * rpg + rpg).map((row) => (
                            <TableRow
                                key={nanoid()}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                            {Object.values(row).map((val) => (
                                <TableCell align="left" key={nanoid()}>
                                    <Typography variant="body1">
                                        {val}
                                    </Typography>
                                </TableCell>
                            ))}
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination 
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={props.individualTable.length}
                    rowsPerPage={rpg}
                    page={pg}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Grid>
    )
}