import React from 'react';
import { FormGroup, FormControlLabel, Switch } from '@mui/material';

export default function ControlledSwitches(props) {

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if ('enableDataLabels' in props) {
      props.enableDataLabels(event.target.checked);
    } 
  };

  return (
    <FormGroup>
        <FormControlLabel control = {
            <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
        } label="Data labels" />
    </FormGroup>
  );
}

