import React from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { Select, MenuItem } from '@mui/material';

export default function MultipleSelect(props) {
  const [choice, setChoice] = React.useState(props.initial_option);

  const handleChange = (selectedOptions) => {
    console.log(selectedOptions.target.value)
    const chart_options_filter = props.chart_options.map(o => o.value);
    var checkedOptions = selectedOptions.target.value.filter(function(id) {
      return chart_options_filter.indexOf(id) > -1;
    })
    setChoice(checkedOptions);
    if ('chooseYAxis' in props) {
      props.chooseYAxis(checkedOptions);
    }
  };

  return (
    <FormControl sx={{ minWidth: 220 }}>
    <FormHelperText>{props.helper_text}</FormHelperText>
        <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={choice}
            label="choice"
            onChange={handleChange}
        >
        {props.chart_options.map((option) => (
            <MenuItem
            key={option.value}
            value={option.value}
            sx={{ typography: 'body2' }}
          >
            {option.label}
          </MenuItem>
        ))}
        </Select>
    </FormControl>
  );
}

