import React from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { Select, MenuItem } from '@mui/material';

export default function SingleSelect(props) {

  const [choice, setChoice] = React.useState(props.initial_option);

  const handleChange = (selectedOptions) => {
    setChoice(selectedOptions.target.value);
    if ('chooseChartType' in props) {
      props.chooseChartType(selectedOptions.target.value);
    } else if ('chooseXAxis' in props) {
      props.chooseXAxis(selectedOptions.target.value);
    }
  };

  return (
    <FormControl sx={{ minWidth: 220 }}>
    <FormHelperText>{props.helper_text}</FormHelperText>
        <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={choice}
            label="choice"
            onChange={handleChange}
        >
        {props.chart_options.map((option) => (
            <MenuItem
              value={option.value}
              key={option.value}
              sx={{ typography: 'body2' }}
            >
            {option.label}
            </MenuItem>
        ))}
        </Select>
    </FormControl>
  );
}

SingleSelect.defaultProps = {
  initial_option:"none"
}

