import React, { useRef } from "react";
import Editor from "@monaco-editor/react";
import { Button, Card } from '@mui/material';

function MonacoApp(props) {
  const editorRef = useRef(null);

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor; 
  }
  
  function showValue() {
    props.sendQuery(editorRef.current.getValue());
  }

  // https://microsoft.github.io/monaco-editor/typedoc/interfaces/editor.IDiffEditorConstructionOptions.html#cursorWidth
  const options = {
    readOnly: false,
    minimap: { enabled: false },
    wordWrap: "on"
  }

  return (
   <Card>
      <Editor
        height="55vh"
        theme="vs-dark"
        defaultLanguage="sql"
        defaultValue="select t2.product_name, t1.quantity, t1.price from test_sales t1 inner join test_products t2 on t2.product_id = t1.id"
        onMount={handleEditorDidMount}
        options={options}
      />
      <Button fullWidth size="large" type="submit" variant="contained" onClick={showValue} sx={{ mt: 0 }}>run query</Button>
   </Card>
  );
}

export default MonacoApp