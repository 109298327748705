import React, { useEffect, useState } from 'react';
// import ReactDOM from 'react-dom/client';
import './App.css';
import * as duckdb from '@duckdb/duckdb-wasm';
// import * as arrow from 'apache-arrow';
import MonacoApp from './components/MonacoEditor';
import ResultTable from './components/ResultTable';
import RechartApp from './components/Rechart';
import { Box, Grid, Container, Typography, Button, CssBaseline, ThemeProvider, createTheme} from '@mui/material';
import { TestSales, TestProducts, InitialFinalTable } from './components/prePopulatedData';
import StickyFooter from './components/StickyFooter';

// for pre-populated data, take it from prepopulateddata.js

function App() {

  const [duckDBInstance, setduckDBInstance] = useState('');
  const [tableData, setTableData] = useState([]);
  const [monacoTableData, setMonacoTableData] = useState([]);
  const [tableNames, setTableNames] = useState([])

  useEffect(() => {
    async function instantiate(duckdb) {
      const CDN_BUNDLES = duckdb.getJsDelivrBundles(),
        bundle = await duckdb.selectBundle(CDN_BUNDLES), // Select a bundle based on browser checks
        worker_url = URL.createObjectURL(
          new Blob([`importScripts("${bundle.mainWorker}");`], {
            type: "text/javascript"
          })
        );
    
      // Instantiate the asynchronus version of DuckDB-wasm
      const worker = new Worker(worker_url),
        logger = new duckdb.ConsoleLogger("DEBUG"),
        db = new duckdb.AsyncDuckDB(logger, worker);
        console.log('established connection with db')
    
      await db.instantiate(bundle.mainModule, bundle.pthreadWorker);
      await db.open({
          path: ':memory:',
          query: {
            castBigIntToDouble: true,
            castDecimalToDouble: true
          }
        }
      );
      URL.revokeObjectURL(worker_url);
    
      setduckDBInstance(db)
      return db;
    }

    // populate DB after instantiate
    async function populateDB(db) {
      const c = await db.connect();
      try {
        await c.query(`create table test_sales (id int, quantity int, price int);`);
        await c.query(`insert into test_sales VALUES (1, 78, 50) \
        , (2,125,100), (3,100,150), (4,250,175) \
        ,(5,300,28), (6,100,32),(7,400,50);`);
        await c.query(`create table test_products (product_id int, product_name varchar);`);
        await c.query(`insert into test_products VALUES (1,'Lasanga') \
        , (2,'Penne'), (3,'Campanelle'), (4,'Bow Tie') \
        ,(5,'Farfalle'), (6,'Ravioli'),(7,'Fettuccine');`);
        setTableNames(['test_sales', 'test_products']);
        setTableData([TestSales, TestProducts]);
        setMonacoTableData([InitialFinalTable]);
      }
      catch (e) {
          console.log(e.message);
        } 
    }

    instantiate(duckdb).then(response => {
      populateDB(response)
    });  
    ;
    // will this work? add data in table as test_table
    // -- populateDB(duckDBInstance);  
    // replace schema result here with prepopulated data from js file 
    // -- setTableNames(['test_data'])
    // -- setTableData([schema_result]);
    // change monaco editor value to select and group by from test_table (doesn't need to trigger)
    // set monaco table data with prepopulated data from js file
    // -- setMonacoTableData([schema_result])
  }, []);

  function previewFile() {
    // const content = document.querySelector('.content');
    const [file] = document.querySelector('input[type=file]').files;
    const reader = new FileReader();

    reader.onload = function (e) {
      // content.innerText = e.target.result;
      var fileName = file.name.replace('.csv','')
      // this will get added even if a table won't be duplicated (bug)
      setTableNames([...tableNames, fileName])
      addTable(duckDBInstance,e.target.result,fileName);
    };

    reader.onerror = function (e) {
      // content.innerText = e.target.result;
      alert(e.target.error);
    };
  
    if (file) {
      reader.readAsText(file);
    }
  };
  
  async function addTable(db, file, name) {
    const c = await db.connect();
  
    await db.registerFileText('text.csv', file);
  
    await c.insertCSVFromPath('text.csv', {
      schema: 'main',
      name: name,
      detect: true,
      header: true,
      delimiter: ','
    });
    
    let query = await c.query(`SELECT * FROM main.${name}`),
      // result = query.toArray(),
      result = query.toArray().map((row) => row.toArray()), 
      schema = query.schema.fields.map(f => f.name);
      var schema_result = combineQueryandSchema(result, schema);
      // const content = document.querySelector('.stringFromDB');
      // content.innerText = result;
      setTableData([...tableData,schema_result]);
    return result;
  }

  function combineQueryandSchema(result, schema) {
    // return list of objects
    var combined = []
      for (let i = 0; i < result.length; i++) {
        var row_of_data = {}
        for (let y = 0; y < schema.length; y++) {
          row_of_data[schema[y]] = result[i][y];
        }
        combined.push(row_of_data)
      }
    return combined
  }
  
  async function sendQueryFromMonaco(queryString) {
    const c = await duckDBInstance.connect();
    try {
      let query = await c.query(queryString),
      result = query.toArray().map((row) => row.toArray()), 
      schema = query.schema.fields.map(f => f.name);
      // content.innerText = result;
      // console.log(result);
      var schema_result = combineQueryandSchema(result, schema);
      // const content = document.querySelector('.stringFromDB');
      if (result.length > 0) {
        // should remove this - array of array . need to fix
        setMonacoTableData([schema_result])
      }
      // console.log(`this is returning from monaco: ${result}`)
    } catch (e) {
      // const content = document.querySelector('.stringFromMonaco');
      // content.innerText = e.message;
      alert(e.message);
    }
  }

  const enableChart = () => {
    if (monacoTableData.length > 0) {
      return <RechartApp dataset={monacoTableData}/>
    } else {
      return <></>
    }
  }

  const input_tables = () => {
    if (tableData.length > 0) {
      return tableData.map((table, index) => 
        <ResultTable 
          individualTable={table} 
          tableName={tableNames[index]} />)
    }
  } 

  const output_table = () => {
    if (monacoTableData.length > 0) {
      // accessing one array in array of arrays
      return ( 
        <ResultTable 
          individualTable={monacoTableData[0]} 
          tableName='results' />
      )
    }
  } 

  // const theme = useTheme();
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  })

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
        <Container maxWidth="xl">
          <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center" >
                <Typography variant="h2" sx={{ mb: 2, mt:5 }} 
                // color='#ffbe88'
                >
                  maca
                <Box
                  sx={{
                    color: '#ffbe88',
                    // color:'white',
                    display: 'inline',
                    fontWeight: 'bold',
                    // mx: 0.5,
                  }}
                >
                  row
                </Box>
                  ni
                </Typography>
                <Typography variant="subtitle2">
                    Skip the infrastructure and make graphs quick. 
                    Upload large CSVs and run SQL fast using DuckDB.  
                    <Box
                  sx={{
                    color: '#ffbe88',
                    // color:'white',
                    display: 'inline',
                    fontWeight: 'bold',
                    mx: 0.5,
                  }}
                >
                  Data is yours. We don't save anything.
                </Box>
                </Typography>
          </Grid>
        
          <Grid container spacing={2}>

            <Grid item xs={12} sm={12} md={12}>
              <input 
                type="file" 
                accept=".csv" 
                required 
                onChange={previewFile} 
                id="raised-button-file"
                className="what"
                hidden
              />
              <Typography variant="h6" sx={{ mt:5 }}>
                Import CSV Files
              </Typography>
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span" className="not">
                  Choose File
                </Button>
              </label>
            </Grid>

            <Grid container spacing={1}>
              {input_tables()}
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={6} sx={{ mt:3 }}>
              <MonacoApp sendQuery={sendQueryFromMonaco}/>
              </Grid>
              {output_table()}
            </Grid>

            <Grid item xs={12} md={12} lg={9} sx={{ mt:3, mb:3}}>
            {enableChart()}
            </Grid>

          </Grid>

          <StickyFooter/>

        </Container>
    </ThemeProvider>
  );
}

export default App;
