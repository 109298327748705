import * as React from 'react';
import { Grid, Link, Typography, Box} from '@mui/material';


function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {'© 2023 Macarowni.'}
    </Typography>
  );
}

function Support() {
    return (
      <Typography variant="body2" color="text.secondary">
        <Link color="inherit" href="mailto:support@macarowni.com" underline="none">
        support@macarowni.com
        </Link>
      </Typography>
    );
  }

  function FavIcon() {
    return (
      <Typography variant="body2" color="text.secondary">
        <Link color="inherit" href="https://www.flaticon.com/free-icons/macaroni" title="macaroni icons"  underline="none">
        Macaroni icons created by smashingstocks - Flaticon
        </Link>
      </Typography>
    );
  }


export default function StickyFooter() {
  return (
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 5,
          borderTop: 1
        }}
      >
        <Grid container direction="row">
          <Grid item xs={12} md={6} lg={4}>
            <Copyright />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Support />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
             <FavIcon />
          </Grid>
        </Grid>
      </Box>
  );
}