import React from 'react';
import { TextField } from '@mui/material';

export default function RechartTextField(props) {
    return (
        <TextField
            value={props.value}
            label={props.label}
            onChange={(e) => {
                props.setText(e.target.value);
            }}
        />
    )
}
