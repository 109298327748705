import React from 'react';
import { Label, LabelList, BarChart, Bar, XAxis, YAxis, Tooltip, Legend , ResponsiveContainer} from 'recharts';
import { colors } from './ChartColors';
// figure out how to map x and y values from data to where you need to place it

function RechartBarChart(props) {

 const YAxisBars = () => {
       if ('yaxis' in props) {
            if (props.datalabels === false) {
                return props.yaxis.map((selectedValue, index) => 
                    <Bar 
                      key={selectedValue} 
                      dataKey={selectedValue} 
                      fill={colors[index % 8]}
                    />
                )
            } else {
                return props.yaxis.map((selectedValue, index) => 
                    <Bar 
                      key={selectedValue} 
                      dataKey={selectedValue} 
                      fill={colors[index % 8]} >
                    <LabelList datakey={selectedValue} position="top" style={{ fontFamily:'Arial', textAnchor:'middle',fontSize:'0.8em',fill:'rgba(0,0,0,1'}} />
                    </Bar>
                )
            }
      }
    }

    const YAxisLabel = () => {
      return <Label angle={-90} value={props.yaxistitle} position='insideLeft' 
      style={{fontFamily:'Arial', textAnchor:'middle',fill:'rgba(0,0,0,1)'}} 
      />
    }
   
    return (
    <ResponsiveContainer width="97%" height={500}>
            <BarChart
            width={400}
            height={600}
            data={props.dataset}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
            >
            <text x="50%" y="5%" fill="black" textAnchor="middle" dominantBaseline="central">
                <tspan fontSize="20">{props.titles}</tspan>
            </text>
          <XAxis 
            dataKey={props.xaxis} 
            style={{
                fontSize: '0.8rem',
                fontFamily: 'Public Sans, sans-serif',
                fill: "black",
                stroke: 'rgba(0,0,0,0.6)'
            }} 
            // angle={-40} 
            // textAnchor="end"
            interval={0} 
            />
          <YAxis 
            domain={[0, dataMax => Math.round(dataMax * 1.20)]}
            style={{
              fontSize: '1rem',
              fontFamily: 'Public Sans, sans-serif',
              fill: "black",
              stroke: 'rgba(0,0,0,0.6)'
            }}>
          {YAxisLabel()}
          </YAxis>
          <Tooltip />
          <Legend iconSize={10} />
          {YAxisBars()}
          <text
                x='80%'
                y='95%'
                style={{ fontFamily: 'Arial', fontSize:'0.5em', fontWeight:'bold', fill:'rgba(0,0,0,0.4)'}}
            >
              Created using Macarowni
          </text>
        </BarChart>
      </ResponsiveContainer>
    );
}

export default RechartBarChart
