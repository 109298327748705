import React from 'react';
import { Grid, Box, List, ListItem } from '@mui/material';
import { useState } from "react";
import SingleSelect from './RechartSubComponents/RechartSelect.js';
import RechartLineChart from './RechartSubComponents/RechartLineChart.js';
import RechartBarChart from './RechartSubComponents/RechartBarChart.js';
import MultipleSelect from './RechartSubComponents/RechartMultipleSelect.js';
import ControlledSwitches from './RechartSubComponents/RechartDataLabels.js';
import RechartTextField from './RechartSubComponents/RechartTextField.js';

function RechartApp(props) {
  const CHART_OPTIONS = [
    { value: 'none', label: 'None' },
    { value: 'line', label: 'Line Chart' },
    { value: 'bar', label: 'Bar Chart' },
    // { value: 'stacked_bar', label: 'Stacked Bar Chart' }
  ];

  let XAXIS_OPTIONS = []
  if (props.dataset && props.dataset[0]) {
    XAXIS_OPTIONS = Object.keys(props.dataset[0][0]).map((key) => ({
      value: key,
      label: key
    }))
    XAXIS_OPTIONS.unshift({value: 'none', label:'None'})
    console.log(XAXIS_OPTIONS)
    console.log(Object.keys(XAXIS_OPTIONS)[0])
  } 

  // initial y axis options; i get back an array of vaues from setYAxis
  let YAXIS_OPTIONS = []
  if (props.dataset && props.dataset[0]) {
    YAXIS_OPTIONS = Object.keys(props.dataset[0][0]).map((key) => ({
      value: key,
      label: key
    }))
    YAXIS_OPTIONS.unshift({value: 'none', label:'None'})
    console.log(YAXIS_OPTIONS)
    console.log([Object.keys(YAXIS_OPTIONS)[0]])
  } 

  const [titles, setTitle] = useState("Title");
  const [yaxistitle, setYaxisName] = useState("Y Axis Label");
  const [chartType, setChartType] = useState('bar');
  const [xaxis, setXAxis] = useState("product_name");
  const [yaxis, setYAxis] = useState(["quantity","price"]);
  const [dataLabelBOOL, setdataLabelBOOL] = useState(true);

  function setChartTitle(value) {
    setTitle(value);
  };

  function chooseChartType(value) {
    setChartType(value);
  };

  function chooseXAxis(value) {
    setXAxis(value);
  }

  function chooseYAxis(value) {
    setYAxis(value);
  }

  function toggleDataLabel(value) {
    setdataLabelBOOL(value);
  }
  
  function setYAxisTitle(value) {
    setYaxisName(value);
  }

  const returnAppropriateChartType = () => {
    if (chartType === 'none') {
      return <></>
    } else if (chartType === 'line') {
      return <RechartLineChart 
        titles={titles} 
        dataset={props.dataset[0]} 
        xaxis={xaxis}
        yaxis={yaxis} 
        datalabels={dataLabelBOOL} 
        yaxistitle={yaxistitle}/>
    } else if (chartType === 'bar') {
      return <RechartBarChart 
        titles={titles} 
        dataset={props.dataset[0]} 
        xaxis={xaxis}
        yaxis={yaxis}
        datalabels={dataLabelBOOL}
        yaxistitle={yaxistitle} />
    } else if (chartType === 'stacked_bar') {
      return <p>not implemented yet</p>
    }
  }

  return (
    <Grid container spacing={1} direction="row">
      <Grid item xs={12} md={4} lg={3}>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <List>
              <ListItem>
                <RechartTextField
                  setText={setChartTitle}
                  value={titles}
                  label="Title"
                  />
              </ListItem>
              <ListItem>
                <RechartTextField
                  setText={setYAxisTitle}
                  value={yaxistitle}
                  label="Y Axis Label"
                  />
              </ListItem>
              <ListItem>
                <SingleSelect 
                  chooseXAxis={chooseXAxis} 
                  chart_options={XAXIS_OPTIONS} 
                  helper_text="X Axis"
                  initial_option="product_name" />
              </ListItem>
              <ListItem>
                <MultipleSelect 
                  chooseYAxis={chooseYAxis} 
                  chart_options={YAXIS_OPTIONS} 
                  helper_text="Y Axis"
                  initial_option={["quantity","price"]}
                   />
              </ListItem>
              <ListItem>
                <SingleSelect 
                  chooseChartType={chooseChartType} 
                  chart_options={CHART_OPTIONS} 
                  helper_text="Chart Type"
                  initial_option="bar" />
              </ListItem>
              <ListItem>
                <ControlledSwitches
                  enableDataLabels={toggleDataLabel} />
              </ListItem>
            </List>
        </Box>
      </Grid>
      <Grid item xs={12} md={8} lg={8} sx={{ backgroundColor:'#dfe0e1' }}>
          {returnAppropriateChartType()}
      </Grid>
    </Grid>
  );
}

export default RechartApp