export const TestSales = [
    {
      id: 1,
      quantity: 78,
      price: 50,
    },
    {
      id: 2,
      quantity: 125,
      price: 100,
    },
    {
      id: 3,
      quantity: 100,
      price: 150,
    },
    {
      id: 4,
      quantity: 250,
      price: 175,
    },
    {
      id: 5,
      quantity: 300,
      price: 28,
    },
    {
      id: 6,
      quantity: 100,
      price: 32,
    },
    {
      id: 7,
      quantity: 400,
      price: 50,
    },
  ];

  export const TestProducts = [
    {
      product_id: 1,
      product_name: 'Lasanga'
    },
    {
      product_id: 2,
      product_name: 'Penne'
    },
    {
      product_id: 3,
      product_name: 'Campanelle'
    },
    {
      product_id: 4,
      product_name: 'Bow Tie'
    },
    {
      product_id: 5,
      product_name: 'Farfalle'
    },
    {
      product_id: 6,
      product_name: 'Ravioli'
    },
    {
      product_id: 7,
      product_name: 'Fettuccine'
    },
  ];

  export const InitialFinalTable = [
    {
      product_name: 'Lasanga',
      quantity: 78,
      price: 50,
    },
    {
      product_name: 'Penne',
      quantity: 125,
      price: 100,
    },
    {
      product_name: 'Campanelle',
      quantity: 100,
      price: 150,
    },
    {
      product_name: 'Bow Tie',
      quantity: 250,
      price: 175,
    },
    {
      product_name: 'Farfalle',
      quantity: 300,
      price: 28,
    },
    {
      product_name: 'Ravioli',
      quantity: 100,
      price: 32,
    },
    {
      product_name: 'Fettuccine',
      quantity: 400,
      price: 50,
    },
  ];
  
  