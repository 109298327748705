import React from 'react';
import { Label, LineChart, Line, XAxis, YAxis, Tooltip, Legend , ResponsiveContainer, LabelList} from 'recharts';
import { colors } from './ChartColors';
// figure out how to map x and y values from data to where you need to place it

function RechartLineChart(props) {

    const YAxisLines = () => {
       if ('yaxis' in props) {
            if (props.datalabels === false) {
                return props.yaxis.map((selectedValue, index) => 
                    <Line 
                        type="monotone"
                        key={selectedValue} 
                        dataKey={selectedValue} 
                        stroke={colors[index % 8]}
                        dot={false}
                        strokeWidth={2} 
                        />
                )
            } else {
                return props.yaxis.map((selectedValue, index) => 
                    <Line 
                        type="monotone"
                        key={selectedValue} 
                        dataKey={selectedValue} 
                        stroke={colors[index % 8]}
                        dot={false}
                        strokeWidth={2} 
                        >
                    <LabelList 
                        datakey={selectedValue} 
                        // position="insideBottomLeft" 
                        position="top"
                        style={{ fontFamily:'Arial', textAnchor:'middle',fontSize:'0.8em',fill:'rgba(0,0,0,1'}} />
                    </Line>
                )
            }
      }
    }

    const YAxisLabel = () => {
        return <Label angle={-90} value={props.yaxistitle} position='insideLeft' 
        style={{fontFamily:'Arial', textAnchor:'middle',fill:'rgba(0,0,0,1)'}} 
        />
    }
    
    return (
        <ResponsiveContainer width="97%" height={500} >
            <LineChart
                width={400}
                height={600}
                data= {props.dataset}
                margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
                }}
            >
            <text x="50%" y="5%" fill="black" textAnchor="middle" dominantBaseline="central">
                <tspan fontSize="20" fontFamily='Arial'>{props.titles}</tspan>
            </text>
            <XAxis 
                dataKey={props.xaxis} 
                style={{
                    fontSize: '0.9rem',
                    fontFamily: 'Public Sans, sans-serif',
                    fill: 'rgba(0,0,0,1)',
                    stroke: 'rgba(0,0,0,0.6)'
                }}
                // angle={-45} textAnchor="end"
                tick='black'
                tickLine='black'
                stroke='black'
                />
            <YAxis
                domain={[0, dataMax => Math.round(dataMax * 1.20)]}
                style={{
                    fontSize: '1rem',
                    fontFamily: 'Public Sans, sans-serif',
                    fill: 'rgba(0,0,0,1)',
                    stroke: 'rgba(0,0,0,0.6)'
                }}
                tick='black'
                tickLine='black'
                stroke='black'
                >
            {YAxisLabel()}
            </YAxis>
            <Tooltip />
            <Legend iconSize={10} iconType='plainline'/>
            {YAxisLines()}
            <text
                x='80%'
                y='95%'
                style={{ fontFamily: 'Arial', fontSize:'0.5em', fontWeight:'bold', fill:'rgba(0,0,0,0.4)'}}
            >
                Created using Macarowni
            </text>
            </LineChart>
        </ResponsiveContainer>
    );
}

export default RechartLineChart
